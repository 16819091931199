<template>
  <div class="form-box">
    <FormTitle :infoName="infoName" />
    <div class="form-list">
      <el-form :model="ruleForm" ref="ruleForm" label-position="top" class="demo-ruleForm" @submit.native.prevent>
        <el-form-item label="请输入演讲稿主题">
          <el-input v-model="ruleForm.title"
                    type="text"
                    placeholder="请输入演讲稿主题"
                    maxlength="50"
                    show-word-limit></el-input>
        </el-form-item>
        <el-form-item label="演讲受众" v-show="speechInfo.crowd">

          <el-radio-group v-model="ruleForm.extParam.speechAudience" class="radio-pc-show">
            <el-radio-button v-for="(item,index) in speechInfo.crowd" :label="item"></el-radio-button>
          </el-radio-group>

          <el-radio-group v-model="ruleForm.extParam.speechAudience" class="radio-h5-show h5-el-radio">
            <el-radio v-for="item in speechInfo.crowd" :label="item">{{item}}</el-radio>
          </el-radio-group>

        </el-form-item>
        <el-form-item label="演讲目的" v-show="speechInfo.aim">
          <el-radio-group v-model="ruleForm.extParam.speechTarget" class="radio-pc-show">
            <el-radio-button v-for="(item,index) in speechInfo.aim" :label="item"></el-radio-button>
          </el-radio-group>
          <el-radio-group v-model="ruleForm.extParam.speechTarget" class="radio-h5-show h5-el-radio">
            <el-radio v-for="item in speechInfo.aim" :label="item">{{item}}</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="演讲字数" v-show="speechInfo.words">
          <el-radio-group v-model="ruleForm.extParam.creationWordsNum" class="radio-pc-show">
            <el-radio-button v-for="(item,index) in speechInfo.words" :label="item">{{item + '字左右'}}</el-radio-button>
          </el-radio-group>
          <el-radio-group v-model="ruleForm.extParam.creationWordsNum" class="radio-h5-show h5-el-radio">
            <el-radio v-for="item in speechInfo.words" :label="item">{{item + '字左右'}}</el-radio>
          </el-radio-group>
        </el-form-item>
      </el-form>
    </div>
    <div class="form-btn">
      <el-checkbox class="form-check" v-model="btnInfo.checked">{{btnInfo.title}}</el-checkbox>
      <el-button style="width: 180px" type="primary" :disabled="!btnInfo.checked" @click="submitForm('ruleForm')">立即创作</el-button>
    </div>

    <NoticeWrapper />
  </div>
</template>

<script>
  import SpeechApi from '@/api/speech'
  import CommonApi from '@/api/common'
  import { orderMixin } from '@/utils/orderMixin'
  import { validateTitle } from '@/utils/validate'
  import FormTitle from "@/components/FormTitle/index.vue"
  import NoticeWrapper from "@/components/NoticeWrapper/index.vue"
  export default {
    components: {NoticeWrapper, FormTitle},
    mixins: [orderMixin],
    props: ['infoName'],
		data() {
			return {
        ruleForm: {
          title: '',
          creationDocId: 12,
          creationDocCode: 'SPEECH',
          channelCode: null,
          extParam: {
            version: 1,
            speechAudience: '',
            speechTarget: '',
            creationWordsNum: null
          }
        },
        // rules: {
        //   title: [
        //     { required: true, message: '请输入演讲稿主题', trigger: 'blur' },
        //     { validator: validateTitle, trigger: 'blur' }
        //   ],
        //   extParam: {
        //     speechAudience: [
        //       { required: true, message: '请选择演讲受众', trigger: 'change' }
        //     ],
        //     speechTarget: [
        //       { required: true, message: '请选择演讲目的', trigger: 'change' }
        //     ],
        //     creationWordsNum: [
        //       { required: true, message: '请选择演讲字数', trigger: 'change' }
        //     ]
        //   }
        // },
        btnInfo: {
          title: '我已阅读并同意: 所有的范文仅用于格式和思路参考',
          checked: true
        },
        speechInfo: {
          crowd: '',
          aim: '',
          words: ''
        },
        paramsData: '', // 储存跳页面的传值
			}
		},
		created() {
      this.getAudience()
      this.getTaget()
      this.getWordsNum()
    },
		methods: {
      //获取受众
      getAudience(){
        SpeechApi.getAudience().then(res => {
          this.speechInfo.crowd = res.data;
          this.ruleForm.extParam.speechAudience = this.speechInfo.crowd[0]
        }).catch(() => {
        })
      },
      //获取目的
      getTaget(){
        SpeechApi.getTaget().then(res => {
          this.speechInfo.aim = res.data;
          this.ruleForm.extParam.speechTarget = this.speechInfo.aim[0]
        }).catch(() => {
        })
      },
      //获取字数
      getWordsNum(){
        SpeechApi.getWordsNum().then(res => {
          this.speechInfo.words = res.data;
          this.ruleForm.extParam.creationWordsNum = this.speechInfo.words[0]
        }).catch(() => {
        })
      },
      submitForm(formName) {
        let _this = this

        if(_this.ruleForm.title === ''){
          return _this.$message({
            message: '请输入演讲稿主题',
            type: 'warning',
            duration: 2000,
            customClass: "focus-message",
          });
        }else if(_this.ruleForm.title.length < 5){
          return _this.$message({
            message: '主题长度需要至少5个字!',
            type: 'warning',
            duration: 2000,
            customClass: "focus-message",
          });
        }

        _this.paramsData = _this.ruleForm
        let info = JSON.stringify(_this.ruleForm.extParam)
        _this.ruleForm.extParam = info
        //生成订单 mixin
        _this.createOrder(_this.ruleForm,_this.paramsData)
        // _this.$refs[formName].validate((valid) => {
        //   if (valid) {
        //     _this.paramsData = _this.ruleForm
        //     let info = JSON.stringify(_this.ruleForm.extParam)
        //     _this.ruleForm.extParam = info
        //     //生成订单 mixin
        //     _this.createOrder(_this.ruleForm,_this.paramsData)
        //   } else {
        //     return false;
        //   }
        // });
      },
      // resetForm(formName) {
      //   this.$refs[formName].resetFields();
      // }
		}
	}
</script>

<style lang="less" scoped>
@import "@/assets/css/pageContent";
</style>
