import request from '@/utils/request'

export default {
  // 获取演讲听众
  getAudience(){
    return request({
      url: '/speech/getAudience',
      method: 'get'
    })
  },
  // 获取演讲目的
  getTaget(){
    return request({
      url: '/speech/getTaget',
      method: 'get'
    })
  },
  // 获取字数
  getWordsNum(){
    return request({
      url: '/speech/getWordsNum',
      method: 'get'
    })
  }
}
